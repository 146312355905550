import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { PageHeader, Form, Button, message, Input } from 'antd';

export class BroadcastPushScreen extends React.Component {
    form = null

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentDidMount() {

    }

    async sendBroadcast() {
        try {
            const form = this.form.current;
            const fields = await form.validateFields(['title', 'content']);
            //Realizar o envio das notificações push

        } catch (err) {
            message.error({ content: 'Ocorreu um erro ao enviar a notificação push!', key: 'messageKey' }, 2000);
            console.log(err);
        }
    }

    render() {
        return (
            <MainWrapper selectedKey={["push"]}>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <PageHeader title="Broadcast PUSH" />
                    <Form ref={this.form} layout="vertical" >
                        <Form.Item label="Título" name="title" rules={[{ required: true, message: 'Título obrigatório' }]}>
                            <Input placeholder="Título" />
                        </Form.Item>
                        <Form.Item label="Conteúdo" name="content" rules={[{ required: true, message: 'Conteúdo obrigatório' }]}>
                            <Input placeholder="Conteúdo" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className="login-form-button" onClick={() => this.sendBroadcast()}> Enviar PUSH </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MainWrapper>
        )
    }
}