import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { Table, Button, PageHeader, Space, Drawer, Popconfirm, message, Input, Form } from 'antd';
import { AdminStore } from '../store/AdminStore';
import Column from 'antd/lib/table/Column';

import '../styles/input.css'
import { DigitranDrawer } from '../components/DigitranDrawer';

export class UsersScreen extends React.Component {
    form = null;
    state = {
        dataSource: [],
        selectedUser: null
    }

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentDidMount() {
        this.loadUsers();
    }

    async loadUsers() {
        const users = await AdminStore.listUsers();
        this.setState({ dataSource: users });
    }

    showDrawer(user) {
        this.setState({ selectedUser: user });
        const form = this.form.current;
        if(form) {
            form.setFieldsValue(user);
        }
        return false;
    }

    async deleteUser(user) {
        try {
            const response = await AdminStore.deleteUser(user.id);
            if (response.success) {
                message.success({ content: 'Usuário excluído com sucesso!', key: 'messageKey' }, 2000);
                await this.loadUsers();
            } else {
                message.error({ content: response.error, key: 'messageKey' }, 2000);
            }
        } catch (err) {
            message.error({ content: 'Ocorreu um erro ao excluir o usuário!', key: 'messageKey' }, 2000);
            console.log(err);
        }
    }

    async saveUser() {
        try {
            const { selectedUser } = this.state;
            const form = this.form.current;
            const fields = await form.validateFields(['name', 'email', 'password', 'passwordConfirm']);

            const request = !selectedUser.id ? AdminStore.createUser : AdminStore.updateUser;
            const response = await request({ ...fields, id: selectedUser.id });

            if (response.success) {
                this.setState({ selectedUser: null });
                if(form) {
                    form.resetFields();
                }
                
                await this.loadUsers();
            } else {
                message.error({ content: response.error, key: 'messageKey' }, 2000);
            }
        } catch (err) {
            message.error({ content: 'Ocorreu um erro ao salvar o usuário!', key: 'messageKey' }, 2000);
            console.log(err);
        }
    }

    render() {
        const { dataSource, selectedUser } = this.state;
        return (
            <MainWrapper selectedKey={["usuarios"]}>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <PageHeader
                        title="Usuários"
                        extra={[
                            <Button className="button-primary" onClick={() => this.showDrawer({})} shape="round" style={{ border: 'none' }}>Novo usuário</Button>,
                        ]}
                    />
                    <Table dataSource={dataSource} pagination={{ position: ["none", "none"] }} >
                        <Column title="Nome" dataIndex="name" key="name" />
                        <Column title="E-mail" dataIndex="email" key="email" />
                        <Column title="" key="actions" align="right"
                            render={(text, record) => (
                                <Space size="middle">
                                    <Button type="link" onClick={() => this.showDrawer(record)}>Editar</Button>
                                    <Popconfirm
                                        title="Você realmente deseja excluir esse usuário?"
                                        onConfirm={() => this.deleteUser(record)}
                                        onCancel={null}
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button type="link">Excluir</Button>
                                    </Popconfirm>

                                </Space>
                            )}
                        />
                    </Table>
                </div>

                <DigitranDrawer
                    title={(selectedUser && selectedUser.name) || 'Novo usuário'}
                    onClose={() => this.setState({ selectedUser: null }, () => this.form.current.resetFields())}
                    visible={!!selectedUser}
                >
                    <Form ref={this.form} layout="vertical" initialValues={selectedUser}>
                        <Form.Item label="Nome" name="name" className={"input-secondary"} rules={[{ required: true, message: 'Nome obrigatório' }]}>
                            <Input placeholder="Nome" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item label="E-mail" name="email" className={"input-secondary"} rules={[{ required: true, message: 'E-mail obrigatório' }]}>
                            <Input placeholder="E-mail" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item label="Senha" name="password" className={"input-secondary"}>
                            <Input.Password placeholder="Senha" style={{ borderRadius: 50 }} />
                        </Form.Item>
                        <Form.Item label="Confirme a senha" name="passwordConfirm" className={"input-secondary"}>
                            <Input.Password placeholder="Confirme a senha" style={{ borderRadius: 50 }} />
                        </Form.Item>
                    </Form>
                    <div>
                        <Button type="primary" className="button-secondary" style={{ float: 'right', border: 'none' }} shape="round" onClick={() => this.saveUser()}> Salvar </Button>
                    </div>
                </DigitranDrawer>
            </MainWrapper>
        )
    }
}