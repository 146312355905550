import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { Table, Button, PageHeader, Space, Drawer, Input, Form } from 'antd';
import { AdminStore } from '../store/AdminStore';
import Column from 'antd/lib/table/Column';


export class ClientsScreen extends React.Component {
    state = {
        dataSource: [],
        selectedClient: null
    }

    componentDidMount() {
        this.loadClients();
    }

    async loadClients() {
        const clients = await AdminStore.listClients();
        this.setState({ dataSource: clients });
    }

    showDrawer(client) {
        this.setState({ selectedUser: client });
    }

    deleteClient(client) {
        //Mostrar confirm e se ok, excluir usuário
    }

    render() {
        const { dataSource, selectedClient } = this.state;
        return (
            <MainWrapper selectedKey={["clientes"]}>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <PageHeader
                        title="Clientes"
                    />
                    <Table dataSource={dataSource} pagination={{ position: ["none", "none"] }} >
                        <Column title="ID" dataIndex="id" key="id" render={(text) => `#${text}`} />
                        <Column title="Nome" dataIndex="name" key="name" />
                        <Column title="E-mail" dataIndex="email" key="email" />
                        <Column title="" key="actions" align="right"
                            render={(text, record) => (
                                <Space size="middle">
                                    {/* <a href="javascript:void(0)" onClick={() => this.showDrawer(record)}>Editar</a>
                                    <a href="javascript:void(0)" onClick={() => this.deleteUser(record)}>Excluir</a> */}
                                </Space>
                            )}
                        />
                    </Table>
                </div>
                <Drawer
                    title={selectedClient && selectedClient.name}
                    placement="right"
                    closable={true}
                    onClose={() => this.setState({ selectedClient: null })}
                    visible={!!selectedClient}
                >
                    <Form layout="vertical">
                        <Form.Item name="name" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                            <Input placeholder="Nome" />
                        </Form.Item>
                        <Form.Item name="email" rules={[{ required: true, message: 'E-mail obrigatório' }]}>
                            <Input placeholder="E-mail" />
                        </Form.Item>
                        <Form.Item name="password">
                            <Input.Password placeholder="Senha" />
                        </Form.Item>
                        <Form.Item name="passwordConfirm">
                            <Input.Password placeholder="Confirme a senha" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary"> Salvar </Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </MainWrapper>
        )
    }
}