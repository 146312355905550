import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { Table, Button, PageHeader, Space, Drawer, Popconfirm, message, Input, Form, Select } from 'antd';
import { AdminStore } from '../store/AdminStore';
import Column from 'antd/lib/table/Column';
import { DigitranDrawer } from '../components/DigitranDrawer';

const { Option } = Select;

export class TaxesScreen extends React.Component {
    form = null;
    state = {
        dataSource: [],
        selectedTax: null
    }

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentDidMount() {
        this.loadTaxes();
    }

    async loadTaxes() {
        const taxes = await AdminStore.listTaxes();
        this.setState({ dataSource: taxes });
    }

    showDrawer(tax) {
        this.setState({ selectedTax: tax });
        const form = this.form.current;
        form && form.setFieldsValue(tax);
    }

    async deleteTax(tax) {
        try {
            const response = await AdminStore.deleteTax(tax.id);
            if (response.success) {
                message.success({ content: 'Taxa excluída com sucesso!', key: 'messageKey' }, 2000);
                await this.loadTaxes();
            } else {
                message.error({ content: response.error, key: 'messageKey' }, 2000);
            }
        } catch (err) {
            message.error({ content: 'Ocorreu um erro ao excluir taxa!', key: 'messageKey' }, 2000);
            console.log(err);
        }
    }


    async saveTax() {
        try {
            const { selectedTax } = this.state;
            const form = this.form.current;
            const fields = await form.validateFields(['description', 'value', 'type']);
            fields.value = `${fields.value}`.replace(/,/g, '.');
            const response = await AdminStore.createTax({ ...fields });

            if (response.success) {
                this.setState({ selectedTax: null });
                form && form.resetFields();
                await this.loadTaxes();
            } else {
                message.error({ content: response.error, key: 'messageKey' }, 2000);
            }
        } catch (err) {
            message.error({ content: 'Ocorreu um erro ao salvar a taxa!', key: 'messageKey' }, 2000);
            console.log(err);
        }
    }


    render() {
        const { dataSource, selectedTax } = this.state;
        return (
            <MainWrapper selectedKey={["taxas"]}>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <PageHeader
                        title="Taxas"
                        extra={[
                            <Button key="new-tax" className="button-primary" shape="round" style={{ border: 'none' }} onClick={() => this.showDrawer({ type: 'VALUE' })}>Nova taxa</Button>
                        ]}
                    />
                    <Table dataSource={dataSource} pagination={{ position: ["none", "none"] }} >
                        <Column title="Taxa" dataIndex="description" key="description" />
                        <Column
                            title="Valor"
                            dataIndex="value"
                            key="value"
                            render={(text, record) => {
                                return record.type === 'PERCENTAGE' ? `${(+record.value).toFixed(2)}%` : ` R$ ${(+record.value).toFixed(2)}`
                            }}
                        />
                        <Column title="" key="actions" align="right"
                            render={(text, record) => (
                                <Space size="middle">
                                    <Popconfirm
                                        title="Você realmente deseja excluir essa taxa?"
                                        onConfirm={() => this.deleteTax(record)}
                                        onCancel={null}
                                        okText="Sim"
                                        cancelText="Não"
                                    >
                                        <Button type={"link"}>Excluir</Button>
                                    </Popconfirm>
                                </Space>
                            )}
                        />
                    </Table>
                </div>
                <DigitranDrawer
                    title={(selectedTax && selectedTax.description) || 'Nova taxa'}
                    onClose={() => this.setState({ selectedTax: null }, () => this.form.current && this.form.current.resetFields())}
                    visible={!!selectedTax}
                >
                    <Form ref={this.form} layout="vertical" initialValues={selectedTax} >
                        <Form.Item label="Nome" name="description" className="input-secondary" rules={[{ required: true, message: 'Nome obrigatório' }]}>
                            <Input placeholder="Nome" />
                        </Form.Item>
                        <Form.Item label="Valor" name="value" className="input-secondary" rules={[{ required: true, message: 'Valor obrigatório' }]}>
                            <Input placeholder="Valor" onChange={(e) => {
                                const v = e.target.value;
                                const onlyNumbers = `${+(`${v}`.match(/\d/g).join(""))}`.padStart(3, '0');
                                this.form.current.setFieldsValue({ value: `${onlyNumbers.substring(0, onlyNumbers.length - 2)},${onlyNumbers.substring(onlyNumbers.length - 2, onlyNumbers.length)}` });
                            }}
                                addonAfter={
                                    (
                                        <Form.Item name="type" noStyle>
                                            <Select>
                                                <Option value="VALUE">R$</Option>
                                                <Option value="PERCENTAGE">%</Option>
                                            </Select>
                                        </Form.Item>
                                    )
                                }
                            />
                        </Form.Item>
                    </Form>
                    <div>
                        <Button className="button-secondary" shape="round" onClick={() => this.saveTax()}> Salvar </Button>
                    </div>
                </DigitranDrawer>
            </MainWrapper>
        )
    }
}