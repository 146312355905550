import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { Table, Button, PageHeader, Space, Drawer, Input, DatePicker, message, Tag, Select } from 'antd';
import { AdminStore } from '../store/AdminStore';
import Column from 'antd/lib/table/Column';
import dayjs from 'dayjs'
import { PaymentDetails } from '../containers/payment/PaymentDetails';
import { SearchOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;
const { Option } = Select;

export class PaymentsScreen extends React.Component {
    state = {
        dataSource: [],
        selectedTransaction: null,
        checkedTransactions: [],
        selectedRowKeys: [],
        filters: {}
    }

    componentDidMount() {
        this.loadTransactions();
    }

    async loadTransactions(filters) {
        const transactions = await AdminStore.listPayments(filters);
        this.setState({
            dataSource: transactions
        });
    }

    async showDrawer(transaction) {
        try {
            const closeMessage = message.loading({ content: `Buscando pagamento #${transaction.id}`, key: 'transactionId' });
            const fullTransaction = await AdminStore.getTransaction(transaction.id);

            if (!fullTransaction) {
                message.warning({ content: `Pagamento não encontrado!`, key: 'transactionId' });
            } else {
                closeMessage();
                this.setState({ selectedTransaction: fullTransaction });
            }

        } catch (err) {
            console.log(err);
            message.error({ content: `Ocorreu um erro ao buscar o pagamento!`, key: 'transactionId' });
        }
    }

    getTransactionStatusTag(status) {
        switch (status) {
            case 'PAYED':
                return <Tag color="success">Pago</Tag>
            case 'ERROR':
                return <Tag color="error">Erro</Tag>
            case 'PROCESSING':
                return <Tag color="processing">Processando</Tag>
            case 'SCHEDULED':
                return <Tag color="default">Agendado</Tag>
            case 'OPENED':
                return <Tag color="default">Em aberto</Tag>
            case 'WAITING_PAYMENT':
                return <Tag color="default">Aguardando pagamento</Tag>
            default:
                return null;
        }
    }

    checkSelectedRows(selectedRows, selectedRowKeys) {
        this.setState({
            checkedTransactions: selectedRows,
            selectedRowKeys
        })
    }

    async bulkSchedule() {
        let closeMessage = null;
        try {
            const { checkedTransactions } = this.state;
            if (checkedTransactions.length > 0) {

                const debitIds = checkedTransactions.map((debit) => {
                    return debit.id;
                });

                closeMessage = message.loading({ content: `Agendando solicitações...`, key: 'transactionId' });
                await AdminStore.scheduleDebits(debitIds);
                // await new Promise((resolve) => setTimeout(resolve, 2000));
                closeMessage();
                this.setState({ checkedTransactions: [], selectedRowKeys: [] }, this.loadTransactions);
            } else {
                message.warn({ content: "Por favor, selecione ao menos uma solicitação" }, 2000);
            }
        } catch (err) {
            if (closeMessage) {
                closeMessage();
            }

            message.error({ content: "Ocorreu um erro ao tentar agendar as solicitações" }, 2000);
            console.log(err);
        }
    }

    getExtraButtonsArray() {
        const { checkedTransactions } = this.state;
        const extraButtons = []

        if (checkedTransactions.length > 0) {
            extraButtons.push(
                <Button key="bulk-schedule" type="primary" onClick={() => this.bulkSchedule()}>Agendar selecionados</Button>
            )
        }

        return extraButtons;
    }

    async handleSearch(selectedKeys, confirm) {
        await this.loadTransactions([]);
        confirm();
        this.setState({
            searchText: selectedKeys[0]
        });
    };

    async handleReset(clearFilters) {
        await this.loadTransactions();
        clearFilters();
        this.setState({ searchText: '' });
    };

    getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => { this.searchInput = node; }}
                        placeholder={`Buscar ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }} >Buscar</Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>Limpar</Button>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) => true,
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select());
                }
            }
        }
    }

    async refreshPage() {
        const { selectedTransaction } = this.state;
        await this.loadTransactions([]);
        if(selectedTransaction) {
            const fullTransaction = await AdminStore.getTransaction(selectedTransaction.id);
            this.setState({
                selectedTransaction: fullTransaction
            });
        }
    }

    render() {
        const { dataSource, selectedTransaction, checkedTransactions, selectedRowKeys } = this.state;
        return (
            <MainWrapper selectedKey={["pagamentos"]}>
                <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                    <PageHeader
                        title="Solicitações"
                        extra={this.getExtraButtonsArray()}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{ paddingTop: 15, paddingBottom: 15, paddingRight: 15 }}>
                            <RangePicker
                                format="DD/MM/YYYY"
                                defaultValue={dayjs().toDate()}
                                placeholder={['Data inicial', 'Data final']}
                                onChange={(momentDates, dataStrings) => {
                                    const newFilters = {
                                        ...(this.state.filters || {}),
                                        startDate: momentDates ? momentDates[0] && momentDates[0].format('YYYY-MM-DD') : null,
                                        endDate: momentDates ? momentDates[1] && momentDates[1].format('YYYY-MM-DD') : null
                                    }

                                    this.setState({ filters: newFilters }, () => {
                                        this.loadTransactions(newFilters);
                                    })

                                }}
                            />
                        </div>
                        <div style={{ paddingTop: 15, paddingBottom: 15, paddingRight: 15 }}>
                            <Select allowClear defaultValue={null} placeholder="Status" style={{ minWidth: 200 }} onChange={(value) => {
                                const newFilters = { ...(this.state.filters || {}), status: value };
                                this.setState({ filters: newFilters })
                                this.loadTransactions(newFilters);
                            }}>
                                <Option value="WAITING_PAYMENT">Aguardando pagamento</Option>
                                <Option value="OPENED">Em aberto</Option>
                                <Option value="SCHEDULED">Agendado</Option>
                                <Option value="PROCESSING">Processando</Option>
                                <Option value="PAYED">Pago</Option>
                                <Option value="ERROR">Erro</Option>
                            </Select>
                        </div>
                    </div>
                    <Table
                        dataSource={dataSource}
                        pagination={{ position: ["none", "none"] }}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys,
                            getCheckboxProps: (record) => ({ disabled: record.status == 'PAYED' || record.status == 'PROCESSING' || record.status == 'SCHEDULED' }),
                            onChange: (selectedRowKeys, selectedRows) => this.checkSelectedRows(selectedRows, selectedRowKeys)
                        }}
                    >
                        <Column title="ID" dataIndex="id" key="id" render={(text) => `#${text}`} {...this.getColumnSearchProps('id')} />
                        <Column title="Renavam" dataIndex="renavam" key="renavam" {...this.getColumnSearchProps('renavam')} />
                        <Column title="Data" dataIndex="created_at" key="created_at" render={(text) => dayjs(text).format(`DD/MM/YYYY HH:mm`)} />
                        <Column title="Total" dataIndex="total" key="total" render={(text) => (+text).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} />
                        <Column dataIndex="status" key="status" render={(text) => this.getTransactionStatusTag(text)} />
                        <Column title="" key="actions" align="right"
                            render={(text, record) => (
                                <Space size="middle">
                                    <a href="#" onClick={() => this.showDrawer(record)}>Visualizar</a>
                                    {/* {['PAYED', 'SCHEDULED', 'PROCESSING'].indexOf(record.status) == -1 && <a href="#" onClick={() => this.bulkSchedule([record.id])}>Agendar</a>} */}
                                </Space>
                            )}
                        />
                    </Table>
                </div>
                <Drawer
                    title={selectedTransaction && `Solicitação #${selectedTransaction.id}`}
                    placement="right"
                    width={'30vw'}
                    closable={true}
                    onClose={() => this.setState({ selectedTransaction: null })}
                    visible={!!selectedTransaction}
                >
                    {selectedTransaction && <PaymentDetails payment={selectedTransaction} onPaymentUpdated={this.refreshPage.bind(this)} />}
                </Drawer>
            </MainWrapper>
        )
    }
}