import React from 'react'
import { Row, Col, Input, Form, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { UserStore } from '../store/UserStore';
import digitranLogo from '../assets/logo-colorida.svg'

import '../styles/login.css';
import '../styles/input.css';

class LoginScreenClass extends React.Component {
    state = {
        loading: false,
        isLogged: false,
        emailError: null,
        passwordError: null
    }

    formRef = null;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            isLogged: UserStore.isLogged()
        })
    }

    async makeLogin() {
        try {
            const { history, onLogged } = this.props;
            const form = this.formRef.current;

            const fields = await form.validateFields(['email', 'password']);

            message.loading({ content: 'Autenticando...', key: 'loginKey' });
            const response = await UserStore.login(fields.email, fields.password);
            if (response.success) {
                message.success({
                    content: 'Login realizado!',
                    key: 'loginKey',
                    duration: 1,
                    onClose: async () => {
                        return onLogged ? onLogged() : history.push('/home');
                    }
                });
            } else {
                message.warning({
                    content: response.error,
                    key: 'loginKey',
                    duration: 1
                });
            }
        } catch (err) {

        }
    }


    render() {
        const { loading, emailError, passwordError } = this.state;
        const { history } = this.props;

        return (
            <>
                <Row gutter={0} className="fullscreen" align="stretch">
                    <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{ backgroundColor: '#2F3A4F' }}>
                        <Row align="middle" className="pd" style={{ height: '100vh' }}>
                            <Col flex="1">
                                <Row align="middle" className="pd" style={{ flex: 1 }}>
                                    <Col xs={2} />
                                    <Col xs={20} style={{ justifyContent: 'center', display: 'flex' }}>
                                        <img src={digitranLogo} width={"60%"} style={{ marginBottom: 40 }} />
                                    </Col>
                                </Row>
                                <Row style={{ flex: 1 }}>
                                    <Col xs={3} />
                                    <Col xs={18} >
                                        <Form ref={this.formRef} layout="vertical">
                                            <Form.Item name="email" rules={[{ required: true, message: 'E-mail obrigatório' }]} validateStatus={!!emailError ? 'error' : null} help={emailError}>
                                                <Input placeholder="E-mail" className="input" style={{ borderRadius: 50 }} />
                                            </Form.Item>
                                            <Form.Item name="password" rules={[{ required: true, message: 'Senha obrigatória' }]} validateStatus={!!passwordError ? 'error' : null} help={passwordError}>
                                                <Input.Password placeholder="Senha" className="input" style={{ borderRadius: 50 }} />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button
                                                    className="button-primary"
                                                    onClick={() => this.makeLogin()}
                                                    shape="round"
                                                    style={{ border: 'none' }}
                                                    loading={loading}
                                                >Entrar</Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={0} sm={0} md={12} lg={14} xl={16} className={"login-background"}></Col>
                </Row>
            </>
        );
    }

}

export const LoginScreen = withRouter(LoginScreenClass);