import React from 'react';
import { MainWrapper } from '../containers/layout/MainWrapper';
import { PageHeader, Input, Empty, message, Divider } from 'antd';
import '../styles/input.css'
import { Request } from '../core/http/Request';
import Configs from '../config.json'

const formatMoney = (price) => 'R$ ' + parseFloat(price).toFixed(2).toString().replace('.', ',')

const formatRenavam = (renavam) => {
    const number = renavam.split('', renavam.length - 1);
    return `${(+(number.join('')))}-${renavam[renavam.length - 1]}`
}

const DebitItem = ({ debit }) => {
    return (
        <div style={{ padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{debit.description}</span>
                <span style={{ fontWeight: 'bold', fontSize: 12 }}>{formatMoney(+debit.value)}</span>
            </div>
        </div>
    )
}

export class HomeScreen extends React.Component {

    state = {
        searching: false,
        vehicle: null
    }

    form = null

    constructor(props) {
        super(props);
        this.form = React.createRef();
    }

    componentDidMount() {

    }

    async setStateAsync(data) {
        return new Promise((resolve) => this.setState(data, resolve));
    }

    async searchVehicle(renavam) {

        await this.setStateAsync({ searching: true });
        const response = await Request.get("", {
            url: `${Configs.CLIENT_SERVER_LOCATION}/search/${renavam}`
        });

        await this.setStateAsync({ searching: false });

        const { success, data, error } = response;
        if (success) {
            this.setState({ vehicle: data });
        } else {
            message.error({ content: error, key: 'messageKey' }, 2000);
        }
    }

    renderDebits(debits) {
        if ((debits || []).length == 0) {
            return (
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Não há débitos para esse veículos
                            </span>
                        }
                    />
                </div>
            )
        }

        return (
            <div style={{ flex: 1 }}>
                {debits.map((debit) => <DebitItem debit={debit} />)}
            </div>
        )
    }

    renderVehicle() {
        const { vehicle } = this.state;

        if (!vehicle) {
            return (
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span>
                                Pesquise um veículo para exibir suas informações
                            </span>
                        }
                    />
                </div>
            );
        }

        const { renavam, placa, marca, modelo, debits } = vehicle;
        return (
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <PageHeader title={formatRenavam(renavam)} subTitle={`Placa: ${placa} - Marca: ${marca || "Desconhecida"} - Modelo: ${modelo || "Desconhecido"}`} style={{ paddingLeft: 0 }} />
                <Divider />
                {this.renderDebits(debits)}
            </div>
        )
    }

    render() {
        const { vehicle, searching } = this.state;
        return (
            <MainWrapper publicScreen={false} selectedKey={["inicio"]}>
                <div style={{ padding: 30 }}>
                    <div style={{ textAlign: 'right', marginBottom: 30 }}>
                        <Input.Search
                            className={"input-secondary"}
                            placeholder="Digite um renavam"
                            enterButton
                            style={{ width: "auto" }}
                            loading={searching}
                            onSearch={(renavam) => this.searchVehicle(renavam)}
                        />
                    </div>
                    <div>
                        {this.renderVehicle()}
                    </div>
                </div>
            </MainWrapper>
        )
    }

}