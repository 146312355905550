import React, { useState } from 'react'
import { Descriptions, Button, Space, Tag, Divider, Card, Menu, Dropdown, message } from 'antd'
import dayjs from 'dayjs'
import { EllipsisOutlined, SyncOutlined, ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { AdminStore } from '../../store/AdminStore';

const getTransactionStatusTag = (status) => {
    switch (status) {
        case 'PAYED':
            return <Tag color="success">Pago</Tag>
        case 'ERROR':
            return <Tag color="error">Erro</Tag>
        case 'PROCESSING':
            return <Tag color="processing" icon={<SyncOutlined />}>Processando</Tag>
        case 'WAITING_PAYMENT':
            return <Tag color="default" icon={<ClockCircleOutlined />}>Aguardando pagamento</Tag>
        default:
            return null;
    }
}

const getDebitStatusText = (status) => {
    switch (status) {
        case 'PAYED':
            return 'Pago';
        case 'OPENED':
            return 'Em aberto';
        case 'SCHEDULED':
            return 'Agendado';
        default:
            return status
    }
}

const markDebitAsPayed = async (debitId, onError) => {
    try {
        const response = await AdminStore.markDebitAsPayed(debitId);
        console.log(response);
        if (response.success) {
            return response;
        }
        throw new Error(response.error);
    } catch (err) {
        onError && onError(err)
    }
}

const payDebit = async (debitId, onError) => {
    try {
        const response = await AdminStore.payDebit(debitId);
        console.log(response);
        if (response.success) {
            return response;
        }
        throw new Error(response.error);
    } catch (err) {
        onError && onError(err)
    }
}

const DebitItem = ({ debit, onDebitUpdated }) => {
    const [loading, setLoading] = useState(false);

    const actions = [];
    const menuExtraActions = [];

    const setLoadingAndCallback = async (callback) => {
        try {
            setLoading(true);
            await callback();
            setLoading(false);
            onDebitUpdated && onDebitUpdated();
        } catch (err) {
            setLoading(false);
        }
    }

    const showAlertError = (errorMessage) => {
        message.error({
            content: errorMessage,
            key: 'erroMessage',
            duration: 1
        });
    }

    if (loading) {
        actions.push(<LoadingOutlined />)
    } else {
        switch (debit.status) {
            case 'PAYED':
                actions.push(
                    <Button 
                        type="link" 
                        disabled={!debit.url_recibo} 
                        onClick={() => window.open(debit.url_recibo, "_blank")}
                    >Abrir recibo</Button>
                );
                break;
            case 'OPENED':
                actions.push(<Button type="link" onClick={() => setLoadingAndCallback(async () => await payDebit(debit.id, (err) => showAlertError(err.message)))}>Pagar débito</Button>);
                menuExtraActions.push(
                    <Menu.Item
                        key="markAsPayed"
                        onClick={() => setLoadingAndCallback(async () => await markDebitAsPayed(debit.id, (err) => showAlertError(err.message)))}
                    >
                        Marcar como pago
                    </Menu.Item>
                );
                break;
        }

        if (menuExtraActions.length > 0) {
            const menu = (<Menu>{menuExtraActions}</Menu>);
            actions.push((
                <Dropdown overlay={menu}>
                    <EllipsisOutlined />
                </Dropdown>
            ))
        }
    }

    return (
        <Card actions={actions}>
            <div><b>{debit.description}</b></div>
            <div>{(+debit.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</div>
        </Card>
    )
}

const DebitGroupItem = ({ status, debits, onDebitUpdated }) => {
    return (
        <div>
            <Divider orientation="left">
                {getDebitStatusText(status)}
            </Divider>
            {debits.map((debit) => <DebitItem debit={debit} onDebitUpdated={onDebitUpdated} />)}
        </div>
    );
}

export const PaymentDetails = ({ payment, onPaymentUpdated }) => {

    const separatedByStatus = payment && payment.debits ? Object.entries(
        payment.debits.reduce((o, debit) => {
            o[debit.status] = [...(o[debit.status] || []), debit]
            return o;
        }, {})
    ).map((entry) => ({ status: entry[0], debits: entry[1] })) : [];


    return (
        <Descriptions>
            <Descriptions.Item span={3}>
                <b>{payment.client}</b>
            </Descriptions.Item>
            <Descriptions.Item label="Data" span={3}>
                {dayjs(payment.created_at).format('DD/MM/YYYY HH:mm')}
            </Descriptions.Item>
            <Descriptions.Item label="Total" span={3}>
                {(+payment.total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            </Descriptions.Item>
            <Descriptions.Item label="" span={3}>
                {getTransactionStatusTag(payment.status)}
            </Descriptions.Item>
            <Descriptions.Item span={3}>
                <Space direction="vertical" style={{ flex: 1 }}>
                    {separatedByStatus.map(({ status, debits }) => <DebitGroupItem status={status} debits={debits} onDebitUpdated={onPaymentUpdated} />)}
                </Space>
            </Descriptions.Item>
        </Descriptions>
    )
}