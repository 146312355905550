import { Request } from "../core/http/Request";
import SecureLS from 'secure-ls';

const store = new SecureLS();

export class UserStore {
    static isLogged() {
        return !!UserStore.getUserToken();
    }

    static setUserToken(token) {
        store.set('userToken', token);
        
    }

    static getUserToken() {
        return store.get('userToken');
    }

    static async login(email, password) {
        const response = await Request.post('login', { email, password });

        if (response.success) {
            UserStore.setUserToken(response.data.token)
        }

        return response;
    }

    static clearStore() {
        store.removeAll();
    }
}