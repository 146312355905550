import React from 'react'
import { Col, Row } from 'antd';
import { UserStore } from '../store/UserStore';
import { Link, useHistory } from 'react-router-dom';
import { DigitranDrawer } from '../components/DigitranDrawer';

import '../styles/input.css'


const MenuItem = ({ text, action }) => {
    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <a onClick={action}>
                <Row>
                    <Col>
                        <span style={{ color: 'white' }}>
                            {text}
                        </span>
                    </Col>
                </Row>
            </a>
        </div>
    );
}

export const MenuDrawer = ({ visible, onClose }) => {
    const history = useHistory();

    return (
        <DigitranDrawer
            title="Menu"
            onClose={onClose}
            visible={visible}
        >
            <MenuItem text="BUSCAR VEÍCULOS" action={() => history.push("/home")} />
            <MenuItem text="PAGAMENTOS" action={() => history.push("/pagamentos")} />
            <MenuItem text="CLIENTES" action={() => history.push("/clientes")} />
            {/* <MenuItem text="NOTIFICAÇÕES" action={() => history.push("/push")} /> */}
            <MenuItem text="TAXAS" action={() => history.push("/configuracoes/taxas")} />
            <MenuItem text="USUÁRIOS" action={() => history.push("/configuracoes/usuarios")} />
            <MenuItem
                text="SAIR"
                action={() => {
                    UserStore.clearStore();
                    history.replace("/");
                }}
            />
        </DigitranDrawer>
    )
}
