import React, { useState } from 'react';
import { Layout, Row, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { UserStore } from '../../store/UserStore';
import { MenuDrawer } from '../MenuDrawer';

import "antd/dist/antd.css";

const { Content, Footer } = Layout;

export const MainWrapper = ({ publicScreen, children }) => {
    const history = useHistory();
    const [showMenuDrawer, setShowMenuDrawer] = useState(false);

    if (!publicScreen) {
        if (!UserStore.isLogged()) {
            history.replace('/login');
        }
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Row style={{ backgroundColor: '#2F3A4F', justifyContent: 'space-between', alignItems: 'center' }}>
                <img className="logo" src={require('../../assets/logo_variação_008.png')} />
                <Button style={{ backgroundColor: 'transparent', color: 'white', border: 'none', boxShadow: 'none' }} onClick={() => setShowMenuDrawer(true)}>
                    <MenuOutlined style={{ fontSize: 25 }} />
                </Button>
            </Row>
            <Layout>
                <Content style={{ margin: '24px 16px 0', backgroundColor: 'white', borderRadius: 16 }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>©{new Date().getFullYear()}</Footer>
            </Layout>
            <MenuDrawer 
                visible={showMenuDrawer} 
                onClose={() => setShowMenuDrawer(false)} 
            />
        </Layout>
    )
}