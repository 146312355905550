import { Request } from "../core/http/Request";
import SecureLS from 'secure-ls';
import { UserStore } from "./UserStore";

// const store = new SecureLS();

export class AdminStore {

    static async listUsers() {
        const token = UserStore.getUserToken();
        const response = await Request.get('users', {
            headers: { 'x-user-token': token }
        });

        if (response.success) {
            return response.data;
        }

        return [];
    }

    static async listClients() {
        const token = UserStore.getUserToken();
        const response = await Request.get('clients', {
            headers: { 'x-user-token': token }
        });

        if (response.success) {
            return response.data;
        }

        return [];
    }

    static async listTaxes() {
        const token = UserStore.getUserToken();
        const response = await Request.get('taxes', {
            headers: { 'x-user-token': token }
        });

        if (response.success) {
            return response.data;
        }

        return [];
    }

    static async listPayments(filters) {

        const token = UserStore.getUserToken();
        const { startDate, endDate, status, offset, limit } = filters || {}
        const requestParams = {};

        if (limit) {
            requestParams.limit = limit
        }

        if (offset) {
            requestParams.offset = offset
        }

        if (startDate && endDate) {
            requestParams.startDate = startDate
            requestParams.endDate = endDate
        }

        if (status) {
            requestParams.status = status
        }

        const response = await Request.get('payments', {
            headers: { 'x-user-token': token },
            params: requestParams
        });

        if (response.success) {
            return response.data;
        }

        return [];
    }

    static async getTransaction(transactionId) {
        const token = UserStore.getUserToken();
        const response = await Request.get(`payments/${transactionId}`, {
            headers: { 'x-user-token': token }
        });

        if (response.success) {
            return response.data;
        }

        return null;
    }


    static async createUser(user) {
        const token = UserStore.getUserToken();
        return await Request.post(`users`, user, {
            headers: { 'x-user-token': token }
        });
    }

    static async updateUser(user) {
        const token = UserStore.getUserToken();
        return await Request.put(`users/${user.id}`, user, {
            headers: { 'x-user-token': token }
        });
    }

    static async deleteUser(userId) {
        const token = UserStore.getUserToken();
        return await Request.delete(`users/${userId}`, {
            headers: { 'x-user-token': token }
        });
    }

    static async createTax(tax) {
        const token = UserStore.getUserToken();
        return await Request.post(`taxes`, tax, {
            headers: { 'x-user-token': token }
        });
    }

    static async deleteTax(taxId) {
        const token = UserStore.getUserToken();
        return await Request.delete(`taxes/${taxId}`, {
            headers: { 'x-user-token': token }
        });
    }

    static async scheduleDebits(debitIds, password) {
        const token = UserStore.getUserToken();
        return await Request.post(`payments/schedule`, { debitIds, password }, {
            headers: { 'x-user-token': token }
        });
    }

    static async markDebitAsPayed(debitId) {
        const token = UserStore.getUserToken();
        return await Request.put(`debits/status/${debitId}/payed`, null, {
            headers: { 'x-user-token': token }
        });
    }
    
    static async payDebit(debitId) {
        const token = UserStore.getUserToken();
        return await Request.post(`debits/${debitId}/pay`, null, {
            headers: { 'x-user-token': token }
        });
    }
}