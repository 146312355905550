import React from 'react'
import { Drawer, Grid, PageHeader } from 'antd';
import '../styles/input.css'

const { useBreakpoint } = Grid;


export const DigitranDrawer = ({ visible, title, children, onClose }) => {
    const screens = useBreakpoint();

    let width = "30vw";

    const screensAtivacted = Object.fromEntries(Object.entries(screens).filter(screen => !!screen[1]));

    if (screensAtivacted.xxl) {
        width = "20vw"
    } else if (screensAtivacted.xl) {
        width = "20vw"
    } else if (screensAtivacted.lg) {
        width = "30vw"
    } else if (screensAtivacted.md) {
        width = "40vw"
    } else if (screensAtivacted.sm) {
        width = "50vw"
    } else {
        width = "80vw"
    }

    return (
        <Drawer
            title={title}
            placement="right"
            closable={true}
            onClose={onClose}
            visible={visible}
            headerStyle={{ display: 'none' }}
            width={width}
            bodyStyle={{ background: 'linear-gradient(135deg, rgba(0,199,73,1) 0%, rgba(0,243,63,1) 100%)', paddingTop: 30 }}
        >
            <PageHeader title={<span style={{ color: "white" }}>{title}</span>} style={{ paddingLeft: 0 }} />
            <>
                {children}
            </>
        </Drawer>
    )
}
