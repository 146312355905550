import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { HomeScreen } from './screens/Home';

import './styles/App.css';
import { LoginScreen } from './screens/Login';
import { ClientsScreen } from './screens/Clients';
import { PaymentsScreen } from './screens/Payments';
import { UsersScreen } from './screens/Users';
import { TaxesScreen } from './screens/Taxes';
import { BroadcastPushScreen } from './screens/BroadcastPush';

const App = () =>  {
	return (
		<Router> 
			<Switch>
				<Route exact path={["/", "/home"]} component={HomeScreen} />
				<Route exact path="/login" component={LoginScreen} />
				<Route exact path="/push" component={BroadcastPushScreen} />
				<Route exact path="/clientes" component={ClientsScreen} />
				<Route exact path="/pagamentos" component={PaymentsScreen} />
				<Route exact path="/configuracoes/usuarios" component={UsersScreen} />
				<Route exact path="/configuracoes/taxas" component={TaxesScreen} />
			</Switch>
		</Router>
	)
}

export default App;
